.stars {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: white;
  border-radius: 50%;
  animation: twinkle linear infinite;
}

@keyframes twinkle {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes move-stars {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 1000px 1000px;
  }
}

.starry-background {
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
    radial-gradient(circle, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  background-size: 100px 100px, 200px 200px;
  background-position: 0 0, 50px 50px;
  animation: move-stars 50s linear infinite;
}

nav .fixed ul a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

nav .fixed ul a:hover {
  color: #FFD700;
  /* Gold color on hover */
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

@keyframes starburst {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.5);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.7), 0 0 40px rgba(255, 255, 255, 0.7), 0 0 60px rgba(255, 255, 255, 0.7);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.5);
  }
}

.hover\:animate-starburst:hover {
  animation: starburst 1s infinite;
}

@keyframes nebula {
  0% {
    transform: scale(1);
    filter: hue-rotate(0deg);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.5);
  }
  50% {
    transform: scale(1.1);
    filter: hue-rotate(180deg);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.7), 0 0 40px rgba(255, 255, 255, 0.7), 0 0 60px rgba(255, 255, 255, 0.7);
  }
  100% {
    transform: scale(1);
    filter: hue-rotate(360deg);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.5);
  }
}

.hover\:animate-nebula:hover {
  animation: nebula 2s infinite;
}

@keyframes cosmos {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.5);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.7), 0 0 40px rgba(255, 255, 255, 0.7), 0 0 60px rgba(255, 255, 255, 0.7);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.5);
  }
}

.hover\:animate-cosmos:hover {
  animation: cosmos 3s infinite ease-in-out;
}

@keyframes gradient-border {
  0% {
    border-image-source: linear-gradient(to right, #1e3a8a, #3b82f6, #9333ea, #f59e0b);
  }
  100% {
    border-image-source: linear-gradient(to right, #f59e0b, #1e3a8a, #3b82f6, #9333ea);
  }
}
